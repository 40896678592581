import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const RespondOfficeActions = () => {
    const seo = {
        metaDesc: 'Respond to Office Actions - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Respond to Office Actions - Inventiv.org" canonical='/respond-to-office-action' seo={seo} />

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container">

                    <h1><strong>Table of Contents</strong></h1>
                    <p><strong>1.1&nbsp; <a href="#oa" class="d-clr">First Office Action (OA)</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.1 <a href="#officeaction" class="d-clr">How to handle an office action</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.2 <a href="#allowance" class="d-clr">First office action allowance (Ex parte Quayle)</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.3 <a href="#response" class="d-clr">Amendment in response to Office Action (OA)</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.4 <a href="#examiner" class="d-clr">How to request an interview with the examiner?</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.5 <a href="#timely" class="d-clr">What happens if a response is not timely filed?</a></strong></p>
                    <p><strong>1.2&nbsp; <a href="#finaloa" class="d-clr">Second or Final Office Action (OA)</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.2.1 <a href="#noticeofallowance" class="d-clr">Notice of Allowance</a></strong></p>
                    <p><strong>1.3&nbsp; <a href="#applicable" class="d-clr">Amendment in response to the final OA, if applicable</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.3.1 <a href="#reconsideration" class="d-clr">Reconsideration</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.3.2 <a href="#amendment" class="d-clr">Amendment</a></strong></p>
                    <p style={{ paddingLeft: 40 + 'px' }}><strong>1.3.3 <a href="#examination" class="d-clr">Request to continue the examination after a final office action</a></strong></p>
                    <p style={{ paddingLeft: 80 + 'px' }}><strong>1.3.3.1 <a href="#rce" class="d-clr">Request for Continued Examination (RCE)</a></strong></p>
                    <p style={{ paddingLeft: 80 + 'px' }}><strong>1.3.3.2 <a href="#continuation" class="d-clr">Continuation application</a></strong></p>
                    <p style={{ paddingLeft: 80 + 'px' }}><strong>1.3.3.3 <a href="#cip" class="d-clr">Continuation-in-part (CIP)</a></strong></p>
                    <p style={{ paddingLeft: 80 + 'px' }}><strong>1.3.3.4 <a href="#divisional" class="d-clr">Divisional application</a></strong></p>
                    <h2 id="oa">1.1 First Office Action (OA)</h2>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>An Office Action is a document written by a patent examiner during examination of a patent application.</li>
                                        <li>You can respond by arguing why the rejection is incorrect, amending the claims, or both.</li>
                                        <li>You can also request an interview.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>After your utility patent application pops out of the PTO examination line (typically between six months or up to two years after filing), the case will be reviewed by an examiner and the result of the examination is stated in a document called a first Office Action (OA) from the USPTO.&nbsp; If you have not heard from the PTO within the expected time, you can file a status inquiry with the USPTO.</p>
                    <p>This OA may contain:</p>
                    <ul>
                        <li>An explanation of defects in your drawing or specifications; and</li>
                        <li>A rejection of your application with detailed citations to prior art that the patent examiner used to support anticipation or obviousness.</li>
                    </ul>
                    <img src="../assets/img/banner/34.png" width="100%" alt="" />
                    <p>The PTO rules requires that:</p>
                    <p>The first Office action must be sufficiently detailed that the pertinency and manner of applying the cited prior art to the claims in each rejection is clearly set forth therein. Where the request for reexamination includes material such as a claim chart to explain a proposed rejection in order to establish the existence of a substantial new question of patentability, the examiner may bodily incorporate the claim chart (or other material) within the Office action. The examiner must, however, carefully review the claim chart (or other material) to ensure that any items incorporated in a statement of the rejection clearly and completely address the patentability of the claims. For actions subsequent to the first Office action, the examiner must be careful to additionally address all patent owner responses to previous actions. If the examiner concludes in any Office action that one or more of the claims are patentable over the cited patents or printed publications, the examiner should indicate why the claim(s) is/are clearly patentable in a manner similar to that used to indicate reasons for allowance.</p>
                    <p>&nbsp;</p>
                    <p>As is common, most application are initially rejected so don’t be discouraged.&nbsp; The examiner will list reasons for rejection, citing to the existence of the element in the prior art and supported by patent laws or rules from the Manual of Patent Examination Procedure (MPEP).&nbsp; You can then see if the references work when combined as suggested, or whether the element is the same as your element as asserted, which will help you respond to the OA.&nbsp; The deadline for responding is typically 3 months from the mailing date of the OA, but it may be less so read the first two pages carefully to identify the response deadline.</p>
                    <p>It is quite common for the office action to raise objection to the drawings or the application text, and rejects some or all claims, typically based on prior art.</p>
                    <p>Objections to Drawings, claims, or description: To respond, you can provide substitute drawings and propose changes to the claim text or description.</p>
                    <img src="../assets/img/banner/35.png" width="100% " alt="" />
                    <p>After the 112 objection/rejection, it is also common for the rejections to include 102 and/or 103 rejections. The rejection from the USPTO will match the text of each claim against specific portions of the prior art, meaning that prior art is very similar to your invention.&nbsp; The OA typically recites statutes in its rejections:</p>
                    <ul>
                        <li>Section 102 rejections means that the examiner thinks your invention is exactly the same as the prior art reference, namely that each and every element of your claim exists in one cited reference.</li>
                        <li>Section 103 rejections means the invention is obvious in view of the prior art. Most often the examiner will use 2-3 references.&nbsp; Obviousness rejection based on one reference is rare, but possible.&nbsp; Rejections based on combining 4 or more references can be fodder for the argument that the examiner is using hindsight from your own application to reconstruct the invention.</li>
                    </ul>
                    <p>A 102 rejection means that the invention is anticipated by a reference.&nbsp; Anticipation means that each and every part of the claims is met by the prior art document.&nbsp; An example of a 102 rejection is shown below</p>
                    <img src="../assets/img/banner/36.png" width="100%" alt="" />
                    <p>In addition, 103 rejections are common.&nbsp; A 103 rejection is an obviousness rejection that argues your claimed invention is obvious over two or more reference. An example is below:</p>
                    <img src="../assets/img/banner/37.png" width="100%" alt="" />
                    <h3 id="officeaction">1.1.1 How to handle an office action</h3>
                    <p>1. You should review the rejection, understanding the basis for the rejection.<br /> 2. Then, you should write a response to each and every point raised in the rejection. You should also be prepared to amend the claims to track your arguments.<br /> 3. Next, call the patent examiner and request an interview. During the interview, you can present your position to the examiner.<br /> 4. After the interview, the examiner will send you an interview summary, and you can respond to the summary if you disagree with it. You should file the response within the deadline, which is typically three months from the mailing date of the Office Action. If you don’t adhere to the deadline, you will incur late fees. If you don’t respond by the sixth month, the USPTO automatically abandons the application and you will need to revive it after petitioning and paying a fee.</p>
                    <h3 id="allowance">1.1.2 First office action allowance (Ex parte Quayle)</h3>
                    <p>While rare, it is possible to have a first office action allowance where the examiner is prepared to allow the patent application once minor non-substantive issues such as minor drawing/specification/claim corrections are resolved.</p>
                    <h3>1.1.3 Amendment in response to Office Action (OA)</h3>
                    <p>After studying the OA, review any materials that the examiner cites to and think of how to describe your invention to counter any of the examiner’s arguments. You should draft your amendment to address issues that the patent examiner has presented. In it, you should include:<br /> • A summary of your amendment;<br /> • Respond to any rejections by describing in detail how you’ve changed your claims or how you differ from prior art, for example; and<br /> • Conclude your letter.</p>
                    <p>Your response to an OA includes a remark section where you argue against the examiner’s reasoning in rejecting the application, and it may also include amendments to the claims.<br /> Again, note the response deadline listed on the second page of the OA. It may be three months after the OA’s mailing date if a response requires substantive changes, or it could be two months for simpler actions such as a restriction requirement if the examiner wants you to select a subset of the claims for examination.</p>
                    <h3 id="examiner">1.1.4 How to request an interview with the examiner?</h3>
                    <p>The PTO has a web-based tool that allows Applicants to schedule an interview with an Examiner for their pending patent application. You can access the form at<br /> https://www.uspto.gov/patent/uspto-automated-interview-request-air-form<br /> The examiner will respond to you within a few days. If you want to communicate electronically with the examiner, you should check the third button. Also, if you want to have video conferencing and screensharing to explain your proposal, you should select Video Conference in the Proposed Type of Interview</p>
                    <img src="../assets/img/banner/38.png" width="100%" alt="" />
                    <h3 id="timely">1.1.5&nbsp; &nbsp;What happens if a response is not timely filed?</h3>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>Each office action has a deadline to respond (typically 3 months); if missed, you can pay late fee the application is termed as abandoned but still can be revived by : buying an extension from USPTO, filing revive, or you can opt for other IP protection applicable for your idea.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>If you do not respond by the deadline, you will have to pay late fees when you respond.&nbsp; While you can buy time to respond with late fee payments, the case will automatically be abandoned if you do not respond within six months from the date of the office action.&nbsp; If that’s the case, the USPTO will send you a Notice of Abandonment.</p>
                    <p>However, you may be able to revive it by:</p>
                    <ul>
                        <li>Filing to revive if the delay was unavoidable. You must do this within six months of the date your application was abandoned, and you have to pay a fee.&nbsp; “Unavoidable” means that the USPTO didn’t receive your amendment or you suffered a severe illness; or</li>
                        <li>Filing to revive if the delay was avoidable but unintentional. You have to do this within three months of abandonment, and the fee is higher than it is for an unavoidable delay.&nbsp; “Avoidable but unintentional” means, for example, that you misread the deadline indicated on the OA.</li>
                    </ul>
                    <p>If those aren’t viable methods, you may:</p>
                    <ul>
                        <li>File a substitute application. This is basically a clone of the abandoned application.&nbsp; However, it does not contain the earlier filing date of the abandoned one.&nbsp; Hence, any prior art that presents itself in between the filing date of the earlier abandoned application and this one can be used against you.&nbsp; Also, note that if the USPTO issues a patent, the term date will be calculated using the substitute application’s filing date.&nbsp; Furthermore, you will still have to pay fees as if that abandoned application never existed.</li>
                        <li>If the case has not published, you may be able to rely on trade secret protection.</li>
                    </ul>
                    <h2 id="finaloa">1.2&nbsp; Second or Final Office Action (OA)</h2>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>Final Office actions are typically second office actions that are sent by the examiner after reviewing the applicant’s response to the first non-final office action.</li>
                                        <li>The response can be a notice of allowance or a rejection</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>After you send your amendment to the USPTO, an examiner will either allow the case, or alternatively send you a second OA called a final office action two to six months later. If the examiner disagrees with your argument, the examiner will address your points in the final office action.&nbsp; You can update your response to the first office action and request another interview with the examiner.</p>
                    <h3 id="noticeofallowance">1.2.1&nbsp;&nbsp; Notice of Allowance</h3>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>A notice of allowance is issued by the United States Patent &amp; Trademark Office to indicate that the invention qualifies for a patent.</li>
                                        <li>However, you need to send the patent issuance fee, and you will receive an Issue Notification months later with the patent number and issue date.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>This means that the examiner says your application meets the elements of patentability.&nbsp; He/she is granting you a patent!</p>
                    <p>You need to send the patent issuance fee within three months of this notice.&nbsp; The examiner may also request formal drawings with this notice.&nbsp; After you send formal drawings plus the fee, you’ll receive an Issue Notification several months later.&nbsp; This includes the patent number and the patent issue date.&nbsp; When the patent issues, you’ll then receive the Letters Patent deed</p>
                    <img src="../assets/img/banner/39.png" width="100%" alt="" />
                    <h2 id="applicable">1.3 Amendment in response to the final OA, if applicable</h2>
                    <p>If you’ve been cut off from changing your claims, you can then respond in the following manner. It is best to consult with an attorney to figure out what’s best for your situation.</p>
                    <h3 id="reconsideration">1.3.1 Reconsideration</h3>
                    <p>Ask the examiner to reconsider his/her final OA via phone, email, or in-person. Additionally, you should file an after-file amendment. The USPTO mainly corresponds via written correspondences, but patent examiners sometimes are available for in-person interviews regarding pending applications. The USPTO is encouraging better and earlier communications between applicants and examiners. Here, the examiner can either agree or send an advisory action that restates his or her position;</p>
                    <h3 id="amendment">1.3.2 Amendment</h3>
                    <p>Amend your claims, as the examiner suggests. It may be helpful if you are willing to narrow the claims by adding more limitations to them.</p>
                    <h3 id="examination">1.3.3 Request to continue the examination after a final office action</h3>
                    <p>There are several ways to do this:</p>
                    <h4>1.3.3.1 Request for Continued Examination (RCE)</h4>
                    <p>With an RCE, you pay a fee and are able to file another amendment after you’ve received a final OA. You can request that you’re after final amendment be considered, along with the arguments. You should file the RCE form before your response deadline expires (typically 3 months from the final OA mailing date).</p>
                    <img src="../assets/img/banner/40.png" width="100%" alt="" />
                    <h4 id="continuation">1.3.3.2 Continuation application</h4>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>A continuation application is a patent application filed by an applicant who wants to add more claims to an invention disclosed in an earlier application of the applicant that has not yet been granted or abandoned.</li>
                                        <li>This type of application is allowed when a patent examiner accepts some but rejected other claims in an application. It is also used as an embodiment of the invention.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p>You can file a continuation application while the original application is still pending.&nbsp; The continuation uses the same specification as the parent or original application, claims the priority date of the parent, and generally names at least one of the same inventors as in the parent. This type of application is often filed when a patent examiner has allowed some but rejected other claims in an application, or when you feel that you have not exhausted all useful ways of claiming different embodiments of the invention during the prosecution of the parent.&nbsp; You can file numerous continuation applications, and the filing dates of all are the same as the filing date of the original application.</p>
                    <p>If you’re successful here, the USPTO will issue you a new serial number and filing date for your continuation, unless you have a successful interference challenge.&nbsp; You need to file this continuation within three months of a rejection.</p>
                    <h4 id="cip">1.3.3.3&nbsp; Continuation-in-part (CIP)</h4>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>A continuation-in-part application (CIP) is based from a pending application, and further <em>adds</em> new subject matter.</li>
                                        <li>The CIP has two priority dates—the date of the parent application and the date for the new matter.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>A CIP is an application filed during the lifetime of an earlier non-PPA or utility application, repeating some substantial portion or all of the earlier non-PPA and adding matter not disclosed in the earlier non-PPA. A CIP has two priority dates, the patent filing date and the CIP filing date. Asks the USPTO to continue some of your original application and allows you to add new matter to your original. &nbsp;Thus, you can bypass the bar to new matter.&nbsp; <a name="_Toc51802942"></a></p>
                    <h4 id="divisional">1.3.3.4 Divisional application</h4>
                    <p><u>&nbsp;</u></p>
                    <table class="table table-bordered mt-3">
                        <tbody>
                            <tr>
                                <td width="623">
                                    <ul>
                                        <li>A divisional patent application is a type of patent application that contains matter from a patent application.</li>
                                        <li>While a divisional application is filed later than the parent application, it claims the same priority.</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Sometimes you may disclose multiple inventions in a single application.&nbsp; However, the USPTO may then issue a “restriction requirement,” which limits each patent application to a single invention. That’s because the patent application fee only pays for the examination of one distinct invention.&nbsp; Because of that issuance, you then need to file a divisional application. With a divisional, you may pursue claims of one of the multiple inventions in the parent application and pursue the other inventions in subsequent divisional applications.</p>
                    <p>You must file a divisional application while the earlier non-PPA application (the parent application) is still pending at the PTO.&nbsp; Once the parent application issues as a patent, you will lose the right to claim priority to the parent case, so a good time to file a divisional is before you pay the issue fee of the parent case.&nbsp; Divisional applications generally have the same specification as the patent application and claim the priority date of the parent.&nbsp; The claims should be focused on a different subject matter than that of the parent case.</p>
                </div>
            </section>
        </Layout>
    )
};

export default RespondOfficeActions;